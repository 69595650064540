<template>
  <div>
    <a-spin :spinning="isLoading">
      <sdPageHeader title="Üye - Üye Grubu Eşleştirmesi" />
      <Main>
        <a-row
          type="flex"
          justify="center"
          :gutter="16"
          align="top"
        >
          <a-col :span="24">
            <sdCards headless>
              <a-table
                :data-source="rawItems"
                :columns="columns"
                row-key="id"
              >
                <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                  <div style="padding: 8px">
                    <a-input
                      ref="searchInput"
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                      @pressEnter="confirm()"
                    />
                    <a-button
                      type="primary"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="confirm()"
                    >
                      <template #icon>
                        <SearchOutlined />
                      </template>
                      Search
                    </a-button>
                    <a-button
                      size="small"
                      style="width: 90px"
                      @click="clearFilters()"
                    >
                      Reset
                    </a-button>
                  </div>
                </template>
                <template #filterIcon="filtered">
                  <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #grupShow="{ record }">
                  <a-select
                    :value="record.group_id"
                    @change="handleGroupSelect(record.id, $event)"
                  >
                    <a-select-option
                      v-for="item in groups"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </template>
                <template #groupSettings="{ record }">
                  <sdFeatherIcons
                    type="settings"
                    style="cursor: pointer"
                    @click="handleOpenModal(record.id)"
                  />
                </template>
              </a-table>
            </sdCards>
          </a-col>
        </a-row>
      </Main>
    </a-spin>
  </div>
  <a-modal
    v-model:visible="addCargoModal"
    title="Bilgileri Güncelle"
    :confirm-loading="false"
    ok-text="Kaydet"
    cancel-text="Kapat"
    @ok="saveCargoCompanyLimitForm"
    @cancel="addCargoModal = false"
  >
    <a-row :gutter="24">
      <a-col :span="8">
        <label style="font-weight: 600">Max. Alacak Limiti</label>
        <a-input
          v-model:value="cargoCompanySettingData.maxCreditLimit"
          style="margin-top: 5px"
          oninput="this.value = this.value.replace(/\D/g, '')"
        />
      </a-col>
      <a-col :span="8">
        <label style="font-weight: 600">Max. Borç Limiti</label>
        <a-input
          v-model:value="cargoCompanySettingData.maxBorrowLimit"
          style="margin-top: 5px"
          oninput="this.value = this.value.replace(/\D/g, '')"
        />
      </a-col>
      <a-col :span="8">
        <label style="font-weight: 600">Bloke Tutarı</label>
        <a-input
          v-model:value="cargoCompanySettingData.maxBlockLimit"
          style="margin-top: 5px"
          oninput="this.value = this.value.replace(/[^-\d]/g, '')"
        />
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>

import { SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
import { Main } from '@/view/styled'

export default defineComponent({
  components: {
    Main,
    SearchOutlined,
  },
  data() {
    return {
      rawItems: [],
      groups: [],
      isLoading: true,
      addCargoModal: false,
      cargoCompanySettingData: {
        maxCreditLimit: '',
        maxBorrowLimit: '',
        maxBlockLimit: '',
      },
      cargoCompanyInfo: {
        selectedRecord: '',
      },
      columns: [
        {
          title: 'Kullanıcı',
          dataIndex: 'title',
          align: 'center',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilter: (value, record) => record.title.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 100)
            }
          },
          sortDirections: ['descend'],
        },
        {
          title: 'Email',
          dataIndex: 'email',
          align: 'center',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilter: (value, record) => record.user_mail.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 100)
            }
          },
        },
        {
          title: 'Firma Adı',
          dataIndex: 'company_name',
          align: 'center',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilter: (value, record) => record.user_company.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 100)
            }
          },
        },
        {
          title: 'Grup',
          align: 'center',
          slots: {
            customRender: 'grupShow',
          },
        },
        {
          align: 'center',
          slots: {
            customRender: 'groupSettings',
          },
        },
      ],
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    saveCargoCompanyLimitForm() {
      this.isLoading = true
      this.$restMethods.postForNoc('/save-cargo-company-limit/' + this.cargoCompanyInfo.selectedRecord, {
        maxCreditLimit: this.cargoCompanySettingData.maxCreditLimit || 0,
        maxBorrowLimit: this.cargoCompanySettingData.maxBorrowLimit || 0,
        maxBlockLimit: this.cargoCompanySettingData.maxBlockLimit || 0,
      }).then(resposne => {
        if ( ! resposne.hasError()) {
          this.$message.success(resposne.getData().message)
          this.addCargoModal = false
        }
        this.isLoading = false
      })
    },
    handleOpenModal(userId) {
      this.cargoCompanyInfo.selectedRecord = userId
      this.isLoading = true
      this.$restMethods.postForNoc('/get-cargo-company-limit/' + userId).then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          this.cargoCompanySettingData.maxCreditLimit = data.maxCreditLimit
          this.cargoCompanySettingData.maxBorrowLimit = data.maxBorrowLimit
          this.cargoCompanySettingData.maxBlockLimit = data.maxBlockLimit
          this.addCargoModal = true
        }
        this.isLoading = false
      })
    },
    handleGroupSelect(userId, newGroupId) {
      this.isLoading = true
      this.$restMethods.postForNoc('/save-user-group-match', {
        user_id: userId,
        group_id: newGroupId,
      }).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(response.getData().message)
        }
        this.isLoading = false
      })
    },
    get() {
      this.isLoading = true
      this.$restMethods.postForNoc('/get-user-group-match').then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          this.rawItems = data.items
          this.groups = data.groups
        }
        this.isLoading = false
      })
    },
  },
})
</script>
<style>
.ant-layout-footer {
  margin-top: 350px;
}
</style>